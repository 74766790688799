/* Index 12 */
.bg-black {
  background-color: $black;
}
/* Hero Twelve */
.gr-text {
  background: linear-gradient(287.85deg, #ff0766 0%, #ff7e5d 95.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.game-bg-dark {
  background-color: $black;
}
.game-btn-mdm {
  display: inline-block;
  padding: 16px 35px;
  background: linear-gradient(287.85deg, #ff0766 0%, #ff7e5d 95.32%);
  border-radius: 50px;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}
.slider {
  padding: 230px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.btn-gradient {
  background: linear-gradient(287.85deg, #ff0766 0%, #ff7e5d 95.32%);
  border-radius: 50px;
  padding: 16px 35px;
  display: inline-block;
  color: var(--bs-white);
  text-decoration: none;
}
.btn-gradient:hover {
  color: #fff;
}

/* Server */
.server-circle {
  position: absolute;
  top: -97px;
  left: -24%;
  z-index: 2;
}
@media (min-width: 320px) and (max-width: 768px) {
  .server-circle {
    display: none;
  }
}
.server-list {
  background-color: #1b1b1b;
  position: relative;
  padding: 25px 20px;
  border-radius: 6px;
  overflow: hidden;
}
.server-list::before {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  filter: blur(100px);
  content: "";
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  left: -127px;
  bottom: -20px;
}
.server-list::after {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  content: "";
  filter: blur(100px);
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  top: -127px;
  right: -20px;
}
.server-list h5 {
  font-size: 18px;
}
.game-card {
  background-color: #1b1b1b;
  border-radius: 6px;
}
.card-top {
  padding: 25px;
}
.card-top h4 {
  color: var(--bs-white);
  font-size: 20px;
  font-weight: 500;
}
.card-bottom a {
  background: linear-gradient(287.85deg, #ff0766 0%, #ff7e5d 95.32%);
  text-align: center;
  border-radius: 0 0 6px 6px;
  padding: 16px 20px;
  color: var(--bs-white);
  text-decoration: none;
}
.game-img li:nth-child(2) {
  margin-left: -20px;
}
.game-img li:nth-child(3) {
  margin-left: -20px;
}
.game-img li:nth-child(4) {
  margin-left: -20px;
}
/* Game Services */
.game-service-bg {
  background-image: url("/service-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.game-service-wrapper {
  background-color: #1b1b1b;
  border-radius: 6px;
  padding: 40px 25px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 320px) and (max-width: 768px) {
  .game-services.ptb-100 {
    padding: 0;
  }
  .mt--200 {
    margin-top: 0;
  }
  .mb--200 {
    margin-bottom: 0;
  }
}
.game-service-wrapper::before {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  filter: blur(100px);
  content: "";
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  left: -127px;
  bottom: -20px;
}
.game-service-wrapper::after {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  content: "";
  filter: blur(100px);
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  top: -127px;
  right: -20px;
}
.game-service-icon {
  width: 60px;
  height: 60px;
  background: #262626;
  text-align: center;
  border-radius: 50px;
  line-height: 60px;
}

/* Game Features */
.big-circle {
  position: absolute;
  right: -300px;
  top: 40px;
}
.game-feature {
  background-color: #1b1b1b;
  border-radius: 10px;
  padding: 30px 20px;
  position: relative;
  overflow: hidden;
}
.game-feature::before {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  filter: blur(100px);
  content: "";
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  left: -127px;
  bottom: -20px;
}
.game-feature::after {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  content: "";
  filter: blur(100px);
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  top: -127px;
  right: -20px;
}
.game-feature-icon {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background-color: #26282e;
  text-align: center;
  line-height: 60px;
}

.feature-ellipse {
  position: absolute;
  left: 10px;
  top: -50px;
}

@media (min-width: 320px) and (max-width: 768px) {
  .feature-ellipse {
    display: none;
  }
  .big-circle {
    display: none;
  }
}
/* Counter */
.game-counter-bg {
  background-image: url("/counter-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.game-price {
  background-color: #0f0f0f;
}
.single-game-price {
  background-color: #1b1b1b;
  border-radius: 15px;
  padding: 50px 24px;
  position: relative;
  overflow: hidden;
  ul {
    li {
      color: $gray-300;
      font-size: $font-size-sm;
    }
  }
}
.single-game-price.active::before {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  filter: blur(100px);
  content: "";
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  left: 50px;
  bottom: -20px;
}
.single-game-price.active::after {
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  content: "";
  filter: blur(100px);
  height: 124px;
  width: 124px;
  border-radius: 50px;
  position: absolute;
  top: 50px;
  right: -20px;
}
.single-game-price p {
  color: rgba(255, 255, 255, 0.8);
}
.price-icon {
  background-color: rgba(255, 255, 255, 0.05);
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.game-price-btn a {
  padding: 16px 0;
  color: $white;
  border: 1px solid $white;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium-light;
  justify-content: center;
}
.game-price-btn a:hover {
  background: linear-gradient(287.85deg, #ff0766 0%, #ff7e5d 95.32%);
  border: 1px solid transparent;
  color: $white;
}

/* Game Faq */
.game-faq {
  background-image: url("/faq-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.game-accordion .accordion-item {
  border-radius: 5px !important;
  margin-bottom: 20px;
  background-color: #151515;
  border: none;
}
.game-accordion .accordion-button {
  background-color: #1b1b1b;
  border-bottom: none;
  border-radius: 5px !important;
  color: var(--bs-white);
}

.game-accordion .accordion-button::after {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "+";
  font-size: 20px;
  background-color: #383838;
  background-image: none;
  font-weight: 100;
  color: var(--bs-white);
}

.game-accordion .accordion-button:not(.collapsed)::after {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none;
  background: linear-gradient(95.45deg, #0082f8 0.64%, #1c40f2 98.23%);
  content: "-";
  font-size: 20px;
  transform: translate(0);
  transform: rotate(0deg);
}

/* Game Review */
.sh-feedback-shape {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 320px) and (max-width: 768px) {
  .sh-feedback-shape {
    display: none;
  }
}
.clients-thumb {
  margin-top: -35px;
}
.sh-feedback-wrapper {
  padding: 0 30px 40px;
  box-shadow: 0 15px 60px rgba(151, 151, 151, 0.05);
  background-color: $github;
  border-radius: 10px;
}
.spacer {
  border: 0;
  border-bottom: 1px solid $gray-800;
  margin: 20px 0;
}
/* Game CTA */
.game-cta {
  background-color: $github;
}

/*Game Footer */
.game-footer {
  padding-top: 220px;
  margin-top: -220px;
}
.game-footer-bg {
  background-color: #1b1b1b;
}
.game-footer-nav h5,
.game-award h5,
.game-payment h5 {
  font-size: 18px;
}
.game-footer-nav h5::after {
  background: linear-gradient(
    90deg,
    rgba(0, 130, 248, 1) 0%,
    rgba(28, 64, 242, 1) 100%
  );
  content: "";
  position: absolute;
  height: 3px;
  width: 40px;
  left: 0;
  bottom: -8px;
}
.game-footer-nav ul li a {
  color: $secondary;
  font-size: $font-size-sm;
  &:hover {
    color: $gray-200;
  }
}
.game-footer-newsletter p {
  color: $secondary;
}
.g-news-letter {
  width: 90%;
}
@media (max-width: 992px) {
  .g-news-letter {
    width: 100%;
  }
}
.g-news-letter .form-control {
  height: 60px;
  border-radius: 50px;
  background: rgba(27, 27, 27, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.g-news-letter button {
  position: absolute;
  border: none;
  top: 0;
  right: 0;
  border-radius: 0 50px 50px 0;
  background: linear-gradient(287.85deg, #ff0766 0%, #ff7e5d 95.32%);
  padding: 16px 40px;
  color: $white;
  font-weight: $font-weight-medium-light;
}
.c-left p,
.c-right {
  font-size: 15px;
}
