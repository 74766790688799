/**
 * Basic typography style for copy text
 */
body {
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2.5rem;
  }
}

.h5,
.h6,
h5,
h6 {
  margin-bottom: 0.375rem;
}
.h5,
.h6,
h5,
h6 {
  font-weight: $font-weight-medium;
}
.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4 {
  font-weight: $font-weight-semibold;
}
@media (min-width: 320px) and (max-width: 767px) {
  .lead {
    font-size: 1.125rem;
  }
  .blockquote {
    font-size: 1.0625rem !important;
  }
}